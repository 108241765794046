<template>
  <div class="modal-card modal-download-excel" style="width: 300">
    <header class="modal-card-head">
      <p class="modal-card-title">Download Excel</p>
      <button type="button" class="delete" @click="onCloseDownloadExcel" />
    </header>
    <section class="modal-card-body">
        <AssessmentPeriod :data="period" />
    </section>
    <footer class="modal-card-foot">
        <button :disabled="isDownloading" type="button" class="button" @click="downloadExcel">Download</button>
    </footer>
  </div>
</template>

<script>
import AssessmentPeriod from '@/components/V2/Assessment/Detail/AssessmentPeriod'
export default {
  components: {
    AssessmentPeriod
  },
  name: 'modal-download-excel',
  props: {
    id: {
      default: () => {},
    },
  },
  data() {
    return {
      isDownloading: false,
      // For period
      period: {
        isUsePeriod: true,
        startPeriod: '',
        endPeriod: '',
      },
    }
  },
  mounted() {
  },
  methods: {
    downloadExcel() {
        this.isDownloading = true;
        this.$store
        .dispatch('downloadExcelResultRaw',{
          id: this.id,
          isUsePeriod: this.period.isUsePeriod,
          startPeriod: this.period.startPeriod,
          endPeriod: this.period.endPeriod,
        })
        .then(() => {
          this.success('Data berhasil didownload')
        })
        .catch(() => {
          this.danger('Data gagal didownload')
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },

    // For close modal link in index
    onCloseDownloadExcel() {
      this.$emit('close-modal')
    },
  },
}
</script>
